import React from "react";
import ReactDOM from "react-dom";
import './output.css'
import "./index.scss";
import 'semantic-ui-css/semantic.min.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import getStore from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import "semantic-ui-css/semantic.min.css";
import 'react-toastify/dist/ReactToastify.css';

React.icons = icons;

const { store, persistor } = getStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
