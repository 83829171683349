export const CurrencyIcon =
  {
    CLP: "$",
    USD: "US",
    EUR: "€",
    GBP: "£",
    UF: "UF",
    MXN: "$",
    UTM: "UTM",
  } || "$";
